import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import Crash from "./../../../nav/fr/Artistes/Crash";
import { Helmet } from "react-helmet";

const CrashPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRASH | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,graffitilegend,graffitimaster,graffitiphotos,graffiticulture,graffitinyc,johnmatoscrash,johncrashmatos,johnmatos,crashart,crashpainting,crashestate,crashpaintingavalable,crashpeinture,crashartwork,crashgallery,crashexhibition,crashgalerie,crashexposition,crashhistory,crashhistoire,crashgraffiti,crashnyc,crashsubwayart,crashcontemporaryart,crashartcontemporain,crashbiography,crashparis,crashspraypaint,crashartstudio,crashbook,crashspraypaint,crashaerosol,postgraffitioldschool,postgraffiti,courantartistique,hiphopeducation,hiphopphilosophie,breakdanceart,crasharchives,johnmatospainting,johnmatosgallery,johnmatosdrawing,johnmatosgalerie,johnmatosgraffiti,johnmatosart"/>
      </Helmet>
      <Crash />
    </Layout>
  );
};

export default CrashPage;
